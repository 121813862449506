<template>
  <div class="home">
    <OrderView
      :data="OrderViewData1"
      ref="orderView"
      @listTypeChange="getProjectDocumentList"
      @buttonImgClick="receive"
    ></OrderView>
    <OrderView
      :data="OrderViewData2"
      uploadListType="text"
      @buttonImgClick="buttonImgClick"
      :limit="10"
      ref="orderView2"
    >
      <div
        slot="confirmUploadSlot"
        class="confirmUploadModal"
        v-if="confirmUploadModal"
      >
        <i class="el-icon-error" @click="confirmUploadModal = false"></i>
        <div class="upload-title">确认项目</div>
        <div class="upload-title">
          当前上传类型为：
          <span class="tips">[ {{ imgTypeText }} ] </span>
        </div>
        <div class="upload-items" v-loading="loading">
          <div
            class="upload-item"
            :class="selectIndex == index ? 'checked' : ''"
            v-for="(item, index) in projectList"
            :key="index"
            @click="
              selectProdectId = item.id;
              selectIndex = index;
              selectPainterUserId = item.painter_user_id;
            "
          >
            <div>项目：[ {{ item.name }} ]</div>
            <div v-if="userType == 'enterprise'">
              <span v-if="item.painter_user_id">
                画手：[ {{ item.painter_user_id }} ]
              </span>
            </div>
          </div>
          <div class="no-content" v-if="!loading && !projectList.length">
            暂无内容
          </div>
        </div>
        <div class="upload-button">
          <div class="button-tips">注意：此操作将会无法撤销或者更改！</div>
          <img
            src="../assets/images/communication/confirmUploadModal-button.png"
            alt="确认上传"
            @click="confirm"
          />
        </div>
      </div>
    </OrderView>
    <div class="back" @click="$router.go(-1)">返回</div>
    <CustomerService></CustomerService>
  </div>
</template>
<script>
var moment = require("moment");
import OrderView from "./OrderView.vue";
import CustomerService from "./CustomerService.vue";
export default {
  components: {
    OrderView,
    CustomerService,
  },
  data() {
    return {
      OrderViewData1: {
        loading: true,
        type: "list",
        change: true,
        top: [],
        center: {},
        list: [],
        buttonImg: require("../assets/images/communication/jieshou.png"),
      },
      OrderViewData2: {
        type: "upload",
        change: true,
        top: [
          { value: `发布时间：${moment().format("YYYY/MM/DD")}`, type: "text" },
          {
            value: require("../assets/images/enterprise/communication/xiugaiwenan.png"),
            type: "img",
          },
        ],
        uploadText: "上传需求文档",
        buttonImg: require("../assets/images/communication/shangchuan.png"),
      },
      imageUrl: "",
      confirmUploadModal: false,
      selectIndex: 0,
      selectProdectId: 0,
      selectPainterUserId: 0,
      projectList: [],
      loading: true,
      iframe: "",
      imgTypeText: "",
    };
  },
  mounted() {
    document.getElementsByClassName("content")[0].style.paddingRight = 0;
    let { type, data, uploadText } = this.$route.params;
    if (type == "centerTypeOfImg") {
      this.OrderViewData1.type = "img";
      this.OrderViewData1.top = [
        {
          value: require("../assets/images/enterprise/test-top.png"),
          type: "img",
        },
      ];
      this.OrderViewData1.center = {
        type: "img",
        img: data.image,
      };
      this.OrderViewData2.top[1].value = require("../assets/images/enterprise/huatu-wenan.png");
      this.OrderViewData2.uploadText = uploadText ? uploadText : "上传需求文案";
      this.selectPainterUserId = data.user_id;
    } else {
      this.getProjectDocumentList();
      if (this.userType == "enterprise") {
        this.OrderViewData1.top = [
          {
            value: require("../assets/images/update1.png"),
            changeValue: require("../assets/images/update-checked.png"),
            type: "img-change",
            key: 1,
          },
          {
            value: require("../assets/images/new1.png"),
            changeValue: require("../assets/images/new-checked1.png"),
            type: "img-change",
            key: 0,
          },
        ];
        this.OrderViewData2.top = [
          {
            value: require("../assets/images/painter-update.png"),
            changeValue: require("../assets/images/painter-update-checked.png"),
            type: "img-change",
            key: 1,
          },
          {
            value: require("../assets/images/painter-new.png"),
            changeValue: require("../assets/images/painter-new-checked.png"),
            type: "img-change",
            key: 0,
          },
        ];
        this.OrderViewData2.uploadText = "上传需求文档";
      } else if (this.userType == "painter") {
        this.OrderViewData1.top = [
          {
            value: require("../assets/images/painter-update.png"),
            changeValue: require("../assets/images/painter-update-checked.png"),
            type: "img-change",
            key: 1,
          },
          {
            value: require("../assets/images/painter-new.png"),
            changeValue: require("../assets/images/painter-new-checked.png"),
            type: "img-change",
            key: 0,
          },
        ];
        this.OrderViewData2.top = [
          {
            value: require("../assets/images/update.png"),
            changeValue: require("../assets/images/update-checked.png"),
            type: "img-change",
            key: 1,
          },
          {
            value: require("../assets/images/new1.png"),
            changeValue: require("../assets/images/new-checked1.png"),
            type: "img-change",
            key: 0,
          },
        ];
        this.OrderViewData2.uploadText = "上传作品文件";
      }
    }
  },
  methods: {
    // 接收文件
    receive() {
      let listType = this.$refs.orderView.listType;
      let imgType = this.$refs.orderView.imgChangeValue;
      let list = this.OrderViewData1.list;
      let ids = [];
      if (listType == 1) {
        list.forEach((list) => {
          list.list.forEach((item) => {
            if (item.select) {
              this.downloadFile(item.package_file);
              ids.push(item.id);
            }
          });
        });
      } else {
        list.forEach((item) => {
          if (item.select) {
            this.downloadFile(item.package_file);
            ids.push(item.id);
          }
        });
      }
      this.$api.projectDocument.receive({ ids: ids.join(",") }).then(() => {
        this.$refs.orderView.listType = 1;
        this.getProjectDocumentList({ listType: 1, imgType });
      });
    },
    downloadFile(url) {
      this.iframe = document.createElement("iframe");
      this.iframe.style.display = "none"; // 防止影响页面
      this.iframe.style.height = 0; // 防止影响页面
      this.iframe.src = url;
      document.body.appendChild(this.iframe); // 这一行必须，iframe挂在到dom树上才会发请求
    },
    // 项目文案列表
    getProjectDocumentList(e = { listType: 0, imgType: 1 }) {
      let { listType, imgType } = e;
      this.OrderViewData1.list = [];
      this.OrderViewData1.loading = true;
      let data = {
        userType: this.userType,
        state: listType || 0,
        imgType: imgType || 0,
      };

      if (listType == 1) {
        data.isTime = 1;
        let search = this.$refs.orderView.search;
        if (search) {
          data.search = search;
        }
      }
      this.$api.projectDocument.list(data).then((res) => {
        if (listType == 0) {
          res.data.forEach((item) => {
            item.select = false;
            item.img = this.judgeFileType(item.files[0]);
          });
        } else if (listType == 1) {
          res.data.forEach((list) => {
            list.list.forEach((item) => {
              item.select = false;
              item.img = this.judgeFileType(item.files[0]);
            });
          });
        }
        this.OrderViewData1.loading = false;
        this.OrderViewData1.list = res.data;
      });
    },
    judgeFileType(file) {
      let fileType = file.split(".").pop().toLowerCase();
      if (fileType == "png" || fileType == "jpg") {
        return file;
      }
      return require("../assets/images/communication/folder.png");
    },
    // 显示选择项目列表
    buttonImgClick() {
      let { type, data } = this.$route.params;
      if (type == "centerTypeOfImg") {
        this.selectProdectId = data.project_id;
        this.confirm();
      } else {
        this.$api.project
          .phase_list({ userType: this.userType, is_communication: true })
          .then((res) => {
            if (this.userType == "enterprise") {
              this.imgTypeText =
                this.$refs.orderView2.imgChangeValue == 1
                  ? "修改文档"
                  : "需求文档";
            } else if (this.userType == "painter") {
              this.imgTypeText =
                this.$refs.orderView2.imgChangeValue == 1
                  ? "修好的作品"
                  : "画好的作品";
            }
            this.projectList = res.data;
            if (res.data.length) {
              this.selectIndex = 0;
              this.selectProdectId = res.data[0].id;
              this.selectPainterUserId = res.data[0].painter_user_id;
            }
            this.loading = false;
            this.confirmUploadModal = true;
          });
      }
    },
    // 确认上传
    confirm() {
      let images = this.$refs.orderView2.images;
      if (!images.length) {
        this.$message({
          message: "请先上传文件",
          type: "warning",
        });
        this.confirmUploadModal = false;
        return;
      }
      if (!this.selectProdectId) {
        this.$message({
          message: "请选择项目",
          type: "warning",
        });
        return;
      }

      let { type } = this.$route.params;
      let img_type = this.$refs.orderView2.imgChangeValue;
      if (type == "centerTypeOfImg") {
        img_type = 0;
      }
      let data = {
        files: images.join(","),
        project_id: this.selectProdectId,
        user_id: this.selectPainterUserId,
        user_type: this.userType == "enterprise" ? "painter" : "enterprise", // 企业上传的给画手查看，画手上传的给企业查看
        img_type,
      };
      this.$api.projectDocument.add(data).then(() => {
        if (this.userType == "painter") {
          this.$router.push("/painter/order_management");
        } else if (this.userType == "enterprise") {
          this.$router.push("/enterprise/task");
        }
      });
    },
  },
  beforeDestroy() {
    if (this.iframe) {
      this.iframe.remove();
    }
    document.getElementsByClassName("content")[0].style.paddingRight = "5vw";
  },
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  position: relative;
  .confirmUploadModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(../assets/images/communication/confirmUploadModal-bg.png) no-repeat;
    background-size: 100% 100%;
    width: 75%;
    padding: 0 6%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .el-icon-error {
      position: relative;
      top: -2vw;
      cursor: pointer;
      font-size: 2vw;
    }
    .upload-title {
      font-size: 8px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 1vw;
      .tips {
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #f8202d;
      }
    }
    .upload-items {
      text-align: left;
      height: 10vw;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .upload-item {
        border-bottom: 1px solid #ffffff;
        padding: 2% 5%;
        cursor: pointer;
      }
      .no-content {
        text-align: center;
        height: 100%;
      }
      .checked {
        background: #407ee3;
        color: #ffffff;
      }
    }
    .upload-button {
      margin: 0 auto;
      margin-top: 5%;
      .button-tips {
        font-size: 5px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #f8202d;
        line-height: 9px;
      }
      img {
        width: 55%;
        cursor: pointer;
      }
    }
  }
  .back {
    color: #fff;
    position: absolute;
    right: 0;
  }
}
</style>
